body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f3f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root:root {
  --adm-color-primary: #05CEA7;
}
#root .ant-layout {
  // background: none;
}
#root .eyerising-header {
  padding: 0 20px;
  background: none;
}
#root .eyerising-content {
  background: none;
}
#root .eyerising-slider {
  min-height: 100vh;
  background: none;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
  .ant-menu {
    flex: 1;
  }
}
#root .eyerising-divider {
  margin: 0px 0;
}
.hidden {
  display: none;
}