#root .chart-line {
  top: 100px;
  z-index: 100;
  position: relative;
  top: 112px;
  left: 50px;
  width: 90%;
  display: block;
  min-width: 95%;
  border-top: 2px dashed #0ED1AD;
}
.ant-modal-content  .chart-line {
  top: 100px;
  z-index: 100;
  position: relative;
  top: 112px;
  left: 50px;
  width: 90%;
  display: block;
  min-width: 95%;
  border-top: 2px dashed #0ED1AD;
}