.pay-input {
  height: 60px;
  border: 1px #eee solid;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px 10px;
}
.pay-card {
  padding: 0 0;
  .ant-card-body {
    padding: 10px 20px;
  }
}