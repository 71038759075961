.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
.App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
}
}
@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}
@media only screen and (max-width: 575px) {
  .portal-signin {
    width: 100%;
    margin-top: 100px;
    padding: 0 20px;
  }
  .portal-signin .signin-form {
      width: 100%!important;
  }

}
@media only screen and (min-width: 575px) {
  .portal-signin {
    margin: 100px auto;
  }
  .portal-signin .signin-form {
      width: 500px;
      margin: 0 auto!important;
      .logo-box {
          text-align: center;
      }
      .App-logo {
        height: 100px;
          pointer-events: none;
          margin: 0 auto 20px;
      }
      .sign-input {
        .ant-input-affix-wrapper {
          padding: 0 20px;
        }
        label {
          height: 48px;
          line-height: 48px;
        }
        input {
          height: 48px;
          box-sizing: border-box;
          line-height: 48px;
        }
      }
      .sign-captch {
          label {
              height: 48px;
          }
          input {
              height: 48px;
          }
      }
  }

}

.portal-signin {
  .sign-submit {
    width: 100%;
    height: 40px;
  }
}