#root .ant-breadcrumb {
  background-color: #419eda;
  color: #fff;
  padding: 10px 0 10px 10px;
  span {
    color: #fff;
  }
}
#root .eyerising-header {
  padding: 0 0;
  .ant-menu {
  }
  .ant-menu-horizontal > .ant-menu-item.sider-title::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 0px;
  }
  .sider-title {
    cursor: default;
  }
  .sider-title:hover {
    color: #333;
  }
}
#root .dashboard {
  .ant-card-head-title {
    font-size: 14px;
  }
}
.eyerising-page-header {
  padding: 10px 0;
}
.ant-layout-sider {
  background: #fff !important;
}
.ant-form-item-label {
  text-align: left;
}
.device-info-item {
  font-size: 17px;
  margin: 20px;
}
#target-content {
  position: absolute;
  top: -2000px;
}
.receipt {
  li {
    padding-left: 50px;
  }
}
#target {
  position: relative;
  background-color: #fff;
  padding: 10px 25px;
  height: 1000px;
  font-family: Calibri;
  width: 700px;
  .machine {
    width: 150px;
    margin-left: 50px;
  }
  h1 {
    font-weight: 400;
    font-family: Calibri;
    font-size: 30px;
    color: #092963;
    font-style: italic;
  }

  h1,
  h2,
  h3 {
    font-family: Calibri;
    font-style: italic;
    color: #21639b;
  }
  h2 {
    font-family: Calibri;
    text-align: center;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 0;
  }
  p {
    font-family: Calibri;
    font-size: 12px;
  }
  footer {
    // margin-top: 20px;
    margin: 0 auto;
    width: 600px;
    position: absolute;
    bottom: 10px;
    p {
      font-family: Calibri;
      text-align: center;
      color: #21639b;
      font-size: 12px;
    }
  }
  .report-profile {
    color: #3acea7;
    font-family: Calibri;
    font-weight: 400;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    h3 {
      font-family: Calibri;
      font-size: 15px;
      color: #3acea7;
      font-weight: 500;
    }
    h4 {
      font-family: Calibri;
      font-weight: 500;
      color: #3acea7;
    }
  }
}

.portal-slider {
  .logout {
    bottom: 10px;
    position: absolute;
  }
  .support {
    bottom: 60px;
    position: absolute;
  }
}

@media only screen and (max-width: 575px) {
  #root .eyerising-header {
    padding: 0;
    background: #fff !important;
  }
  .portal-layout {
    .ant-layout {
    }
  }
  .portal-sider {
    display: none;
  }
  .portal-header {
    display: block;
    width: 100vw;
    height: 60px;
    .adm-tabs-tab-wrapper {
      height: 60px;
      line-height: 50px;
    }
  }
  .portal-breadcurmb {
    display: none;
  }
  .portal-submit {
    margin-top: 20px;
    width: 100%;
    height: 40px;
  }
  .search {
    margin-bottom: 10px;
  }
  .portal-account-actions {
    display: none;
  }
  .portal-account-menu {
    display: block;
  }
  .orders-modal {
    .ant-modal-body {
      padding: 0 5px;
      .ant-table-thead {
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 575px) {
  .portal-layout {
    .ant-layout {
    }
  }
  .portal-sider {
    display: block;
    .ant-menu-inline {
      height: 100%;
    }
  }
  .portal-breadcurmb {
  }
  .portal-header {
    display: none;
  }
  .portal-submit {
    margin-top: 20px;
    min-width: 150px;
    height: 40px;
  }
  .portal-account-menu {
    display: none;
  }
  .portal-account-actions {
    display: block;
    .ant-btn {
      margin-right: 10px;
    }
  }
}
