#root .eyerising-header {
  padding:  0 0;
  .ant-menu  {
    width: 100%;
    display: block;
  }
  .ant-menu-horizontal > .ant-menu-item.sider-title::after, .ant-menu-horizontal > .ant-menu-submenu::after{
    border-bottom: 0px;
  }
  .sider-title {
    cursor:default;
  }
  .sider-title:hover {
    color: #333;
  }
}
.eyerising-page-header {
  padding: 10px 0;
}
.ant-layout-sider {
  background: #fff!important;
}
.css-1wa3eu0-placeholder {
  color: #000!important;
}
.ant-input[disabled] {
  color:#333!important
}
.ant-picker-input > input[disabled] {
  color:#333!important;

}.eyerising-sider {
  width: 250px!important;
  max-width: 250px!important;
  flex: 1!important;
}

.css-1wy0on6 {
  display: none!important;
}


#root .user-workspace .ant-breadcrumb {
  background-color: #00357d;
}
#root .admin-workspace .ant-breadcrumb {
  background-color: #00357d;
}