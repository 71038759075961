.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
.App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
}
}
@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}
@media only screen and (max-width: 575px) {
  .sign-layout  {

    .sign-sider {
      display: none;
    }
    .sign-content {
      // background-color: #0d5aac;
      max-width: 100%;
      padding-top: 50px;
      .sign-form {
        padding: 20px 30px!important;
        label {
          // color: #fff;
        }
        .ant-form-item {
          margin-bottom: 10px;
        }
      }
      .mobile-location {
        display: block!important;
        img {
          display: block;
          margin-bottom: 20px;;
        }
        h2 {
          margin: 10px 0;
          color: #05CEA7;
          font-size: 27px;
        }
      }
    }
  }
}

$form-input-height: 48px;

.sign-sider {
  width: 40%;
  min-height: 100vh;
  background-color: #0d5aac!important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  .ant-layout-sider-children {
    position: relative;
  }
  .AuthLeftBlock_logo__29W55 {
    display: inline-block;
    position: absolute;
    top: 40px;
    left: -9%;
  }
  .AuthLeftBlock_h2__38SEX {
    margin: 0;
    color: #05CEA7;
    font-size: 40px;
    margin-top:47vh;
  }
}
.sign-content {
  background-color: #f5f3f1;
  width: 100%;
  max-width: 800px;
  min-height: 100vh;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 200px;
  .mobile-location {
    display: none;
  }
}
.sign-layout {
  background-color: #f5f3f1;
  width: 100%;
}
.sign-form {
    width: 100%;
    padding: 20px 30px;
    margin: 0 auto;
    .logo-box {
        text-align: center;
    }
    .App-logo {
        height: 200px;
        pointer-events: none;
        margin: 0 auto;
    }
    .sign-input {
      .ant-input-affix-wrapper {
        padding: 0 20px;
      }
      label {
        height: $form-input-height;
        line-height: $form-input-height;
      }
      input {
        height: $form-input-height;
        box-sizing: border-box;
        line-height: $form-input-height;
      }
    }
    .sign-select {
      label {
        height: $form-input-height;
        line-height: $form-input-height;
      }
      .ant-select-selector {
        height: $form-input-height;
        box-sizing: border-box;
        line-height: $form-input-height;
        .ant-select-selection-item {
          line-height: $form-input-height;
        }
      }
    }
    .sign-submit {
      label {
        opacity: 0;
      }
      button {
        height: $form-input-height;
      }
    }
    .sign-captch {
        label {
            height: $form-input-height;
        }
        input {
            height: $form-input-height;
        }
    }
}